<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="专场名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入专场名称"></a-input>
          </a-form-item>

          <a-form-item label="是否在首页展示" name="isRecommend" class="ui-form__item">
            <a-select v-model:value="formState.isRecommend" placeholder="请选择是否在首页展示" allow-clear style="width: 190px;">
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 190px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['mall_activity_hot_add']" type="primary" @click="onAdd">新增活动专场</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'isRecommend'">
              是否在首页展示
              <a-tooltip style="margin-left: 6px;">
                <template #title>不配置则商城首页专场位留白</template>
                <Icon icon="QuestionCircleOutlined"></Icon>
              </a-tooltip>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'isRecommend'">
              {{ record.isRecommend ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['mall_activity_hot_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_activity_hot_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_activity_hot_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_activity_hot_del']" @click="onDelete(record)">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
  <temp v-if="showModal" :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import {
  deleteAppSell,
  getAppSellList,
  pullOffShelvesAppSell,
  pullOnShelvesAppSell
} from "../../../../service/modules/mall";
export default {
  components: {
    Icon,
    temp
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      searchData: {},
      formState: {},
      columns: [{
        title: '专场名称',
        dataIndex: 'title',
      }, {
        title: '商品数量',
        dataIndex: 'goodsNum',
      }, {
        title: '是否在首页展示',
        key: 'isRecommend',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onAdd() {
      this.id = 0;
      this.isEdit = false;
      this.showModal = true;
    },
    onEdit(item) {
      this.id = item.id;
      this.isEdit = true;
      this.showModal = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getAppSellList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该专场活动吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret;
            if(item.isDisabled) {
              ret = await pullOnShelvesAppSell({id: item.id});
            } else {
              ret = await pullOffShelvesAppSell({id: item.id});
            }
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认要删除该活动专场吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteAppSell({id: item.id});
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
